import request from '@/utils/request-service-admin.js'

const clan = {
    // ========================================
    getClanInfoListByPage: async (totalRows, pageIndex) => {
        return await request({
            url: '/admintool/clan/get-clan-list',
            method: 'post',
            data: {
                totalRows: totalRows,
                pageIndex: pageIndex
            }
        })
    },

    // ========================================
    getTotalClan: async () => {
        return await request({
            url: '/admintool/clan/get-total-clan',
            method: 'post',
            data: {}
        })
    },

    // ========================================
    searchClanByClanName: async (inputString) => {
        return await request({
            url: '/admintool/clan/search-clan-by-clan-name',
            method: 'post',
            data: { inputString: inputString }
        })
    },

    // ========================================
    searchClanByClanID: async (inputString) => {
        return await request({
            url: '/admintool/clan/search-clan-by-clan-id',
            method: 'post',
            data: { inputString: inputString }
        })
    },

    // ========================================
    searchClanByRegionID: async (inputString) => {
        return await request({
            url: '/admintool/clan/search-clan-by-region-id',
            method: 'post',
            data: { inputString: inputString }
        })
    },

    // ========================================
    searchClanByClanLevel: async (inputString) => {
        return await request({
            url: '/admintool/clan/search-clan-by-level',
            method: 'post',
            data: { inputString: inputString }
        })
    },

    // ========================================
    updateClanInfo: async (clanInfo) => {
        return await request({
            url: '/admintool/clan/update-clan-info',
            method: 'post',
            data: { clanInfo: clanInfo }
        })
    },

    // ========================================
    setClanLeaderFromDeputy: async (clanID, leaderID, deputyID) => {
        return await request({
            url: '/admintool/clan/set-clan-leader-from-deputy',
            method: 'post',
            data: {
                clanID: clanID,
                userID: leaderID,
                memberUserID: deputyID
            }
        })
    },

    // ========================================
    setClanStatus: async (clanID, status) => {
        return await request({
            url: '/admintool/clan/set-clan-status',
            method: 'post',
            data: {
                clanID: clanID,
                status: status
            }
        })
    },

    // ========================================
    setClanDeputyFromMember: async (clanID, memberUserID) => {
        return await request({
            url: '/admintool/clan/set-clan-deputy-from-member',
            method: 'post',
            data: {
                clanID: clanID,
                memberUserID: memberUserID
            }
        })
    },

    // ========================================
    kickMember: async (clanID, memberUserID) => {
        return await request({
            url: '/admintool/clan/kick-member',
            method: 'post',
            data: {
                clanID: clanID,
                memberUserID: memberUserID,
            }
        })
    },

    // ========================================
    requestKickMemberCheat: async (clanID, memberUserID) => {
        return await request({
            url: '/admintool/clan/request-kick-member-chuoi',
            method: 'post',
            data: {
                clanID: clanID,
                memberUserID: memberUserID,
            }
        })
    },

    // ========================================
    sendMail: async (dataMail) => {
        return await request({
            url: '/admintool/clan/send-mail-clan',
            method: 'post',
            data: dataMail
        })
    },
}

export default clan;